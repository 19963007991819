import { arrayRemove } from '@datorama/akita';
import { type AxiosInstance } from 'axios';

interface DeleteAttachmentOptions {
   httpClient: AxiosInstance;
   version: string;
   entityName: string;
   store: any;
   entityId: number;
   entityAttachmentId: number;
   setError: (error: any) => void;
}

/**
 * Deletes an attachment and updates the store.
 *
 * @param options An object containing all parameters needed for deleting an attachment.
 * @returns {Promise<void>}
 */
export async function deleteAttachmentHandler(options: DeleteAttachmentOptions): Promise<void> {
   const { httpClient, version, entityName, store, entityId, entityAttachmentId, setError } = options;

   try {
      await httpClient.delete(`/${version}/${entityName}/${entityId}/attachments/${entityAttachmentId}`);
      store.update(entityId, ({ attachments }: { attachments: any[] }) => ({ attachments: arrayRemove(attachments, entityAttachmentId) }));
   } catch (error) {
      setError(error);
   }
}
