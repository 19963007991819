import { Backdrop } from '@mui/material';
import EnvoriaLoadingIcon from 'components/v2/EnvoriaLoadingIcon';
import PropTypes from 'prop-types';

function FullPageLoadingIndicator({ open = true, children = null, invisible = false, ...restOfProps }) {
   return (
      <Backdrop
         invisible={invisible}
         open={open}
         sx={[
            (theme) => ({
               zIndex: theme.zIndex.modal + 1,
               backgroundColor: invisible ? 'inherit' : undefined,
            }),
         ]}
         {...restOfProps}
      >
         <EnvoriaLoadingIcon />
         {children}
      </Backdrop>
   );
}
FullPageLoadingIndicator.propTypes = {
   open: PropTypes.bool,
   invisible: PropTypes.bool,
   children: PropTypes.node,
};
export default FullPageLoadingIndicator;
